<template>
  <div style=" background: white;
        padding: 20px;border-radius: 6px">
    <div>
      <search-com @onSubmit="search" @reset="search"  @exportExcel="exportExcel"></search-com>
    </div>
    <el-table
        :data="bill"
        @selection-change="select"
        v-loading="loading"
        style="width: 100%">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          type="index"
          label="序号">
      </el-table-column>

      <el-table-column
          prop="changeDate"
          label="账单日期">
      </el-table-column>

      <el-table-column
          prop="changeType"
          label="收支类型">
        <template slot-scope="scope">
          <div v-if="scope.row.changeType == 1">
            <el-tag type="warning">支出</el-tag>
          </div>
          <div v-if="scope.row.changeType == 2">
            <el-tag type="success">收入</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="oldAmount"
          label="初始金额">
      </el-table-column>

      <el-table-column
          prop="amount"
          label="变更金额">
      </el-table-column>

      <el-table-column
          prop="newAmount"
          label="最终金额">
      </el-table-column>

      <el-table-column
          label="交易类型">
        <template slot-scope="scope">
          <el-tag size="small"  :color="scope.row.transactStyle" style="color: #fff">{{scope.row.transactLabel}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          label="状态">
        <template slot-scope="scope">
          <el-tag size="small" :color="scope.row.statusStyle" style="color: #fff">{{scope.row.statusLabel}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="memo"
          label="备注信息">
      </el-table-column>

      <el-table-column
          prop="createTime"
          label="记录时间">
      </el-table-column>

    </el-table>
    <div style="height: 65px">
      <el-pagination
          style="float: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[50, 200, 800, 1200]"
          layout="total, pager,  sizes"
          :total="total">
      </el-pagination>
      <bill-info ref="billInfo"></bill-info>
    </div>
  </div>

</template>

<script>
import {BILL} from "../../api/base";
import SearchCom from './components/searForm'
import BillInfo from '../taskList/components/billInfo'

export default {
  name: "bill",
  data(){
    return {
      loading:true,
      bill:[],
      selectList:[],
      total:0,
      listForm:{
        pageIndex:1,
        pageSize:50,
      }
    }
  },
  components:{
    SearchCom,
    BillInfo,
  },
  mounted() {
    this.listForm.beginDate = this.formmateTime(new Date())
    this.listForm.endDate= this.formmateTime(new Date())
    this.getList()
  },
  methods:{
    select(selection){
      this.selectList = selection;
    },
    formatJson: function(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    exportExcel(){
      if(this.selectList.length== 0){
        return this.$mes({
          message: '请至少选择一条数据',
          type:'warning'
        })
      }
      this.selectList.forEach(item=>{
        if(item.changeType == 1){
          item['changeTypeName'] = '支出'
        }else{
          item['changeTypeName'] = '收入'
        }
      })
      import('@/tools/Export2Excel').then(excel => {
        const tHeader = ['账单日期','收支类型','初始金额', '变更金额', '最终金额', '交易类型','状态', '备注', '记录时间',]
        const filterVal = ['changeDate', 'changeTypeName','oldAmount', 'amount', 'newAmount', 'transactLabel', 'statusLabel',  'memo', 'createTime', ]
        const list = this.selectList;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '资金明细数据',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formmateTime(time){
      let nowDate = new Date(time);
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate  .getDate();
      return  year + "-" + month + "-" + day;
    },
    handleSizeChange(val){
      this.listForm.pageSize = val;
      this.getList()
    },
    handleCurrentChange(val){
      this.listForm.pageIndex = val;
      this.getList()
    },
    search(form){
      this.listForm.pageIndex = 1;
      Object.assign(this.listForm,form)
      this.getList()
    },
    getList(){
      this.selectList = [];
      this.loading = true;
      this.$axios.get(BILL,this.listForm).then(resp=>{
        this.loading = false;
        if(resp.code == 200){
          this.bill = resp.data.data;
          this.total = resp.data.totalRow;
        }
      })
    }
  },
}
</script>

<style scoped>

</style>