<template>
    <div>
        <el-form :inline="true" :model="formInline" >

          <el-form-item label="收入类型">
            <el-select v-model="formInline.changeType" placeholder="请选择收入类型"  class="inputWidth" clearable filterable >
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in changeTypeList" :key="index"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="交易类型">
            <el-select v-model="formInline.transactType" placeholder="请选择交易类型"  class="inputWidth" clearable filterable >
              <el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item,index) in transactTypeList" :key="index"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="账单状态">
            <el-select v-model="formInline.status" placeholder="请选择账单状态"  class="inputWidth" clearable  >
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in statusList" :key="index"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="账单日期">
              <el-date-picker
                      @change="change"
                      v-model="times"
                      type="daterange"
                      clearable
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
              </el-date-picker>
          </el-form-item>


          <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" >查询</el-button>
              <el-button type="primary" @click="reset" icon="el-icon-refresh">重置</el-button>
              <el-button type="primary" @click="exportExcel" icon="el-icon-download" >下载</el-button>

          </el-form-item>

        </el-form>
    </div>
</template>

<script>
    import {DICTSELECT} from "../../../api/base";

    const formInline = {
      changeType:'',
      status: '',
      transactType:'',
      beginDate: '',
      endDate: '',
    };
    const statusList = [
      {name:'有效',id:1},
      {name:'已扣除',id:2},
      {name:'已过期',id:3},
      {name:'冻结中',id:4},
      {name:'冻结返还',id:5},
      {name:'冻结扣减',id:6},

    ];
    const changeTypeList = [
      {name:'支出',id:1},
      {name:'收入',id:2},

    ];

    export default {
        name: "taskList",
        props:{
            flagType:{
                default:()=>{
                    return [];
                }
            },

        },
        data() {
            return {
              times:[new Date(),new Date()],
              formInline,
              statusList,
              changeTypeList,
              transactTypeList:[],
            }
        },
        methods: {
            getTransactType(){
              this.$axios.get(DICTSELECT,{dictType:'seller_transact_type'}).then(resp=>{
                if(resp.code == 200){
                  this.transactTypeList = resp.data['seller_transact_type']
                }
              })
            },
            change(arr){
                if(arr){
                    this.formInline.beginDate = this.formmateTime(arr[0])
                    this.formInline. endDate= this.formmateTime(arr[1])
                }else{
                    this.formInline.beginDate = undefined;
                    this.formInline.beginDate = undefined;
                }

            },
            formmateTime(time){
                let nowDate = new Date(time);
                let year = nowDate.getFullYear();
                let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
                let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate  .getDate();
                return  year + "-" + month + "-" + day;
            },
            onSubmit(){
                this.$emit('onSubmit',this.formInline)
            },
            exportExcel(){
                this.$emit('exportExcel')
            },
            reset(){
                for(let key in this.formInline ){
                    this.formInline[key] = undefined;
                }
                this.times = [new Date(),new Date()]
                this.change(this.times)
                this.$emit('reset',formInline)
            },
        },
        components: {

        },
        mounted() {
          this.change(this.times);
          this.getTransactType()
        }
    }
</script>

<style scoped>
    .inputWidth{
        width: 230px;
    }
</style>